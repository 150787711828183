import FormRegistration from "./views/FormRegistration";
// import FormMain from "./views/FormMain.vue";
import MainPage from "./views/MainPage.vue";
import { createRouter, createWebHashHistory } from "vue-router";
// import PageCardVue from "./views/PageCard.vue";

export default createRouter({
  history: createWebHashHistory(),
  routes: [
    {path: '/', component: MainPage},
    // { path: "/", component: FormMain },
    { path: "/registration", component: FormRegistration },
    // { path: "/card", component: PageCardVue},
  ],
});
