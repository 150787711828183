<template>
  <div class="add-children">
    <div class="add-children__inner">
      <div class="added-childrens">
        <div
          class="added-children"
          v-for="(children, index) of childrens"
          :key="children.id"
        >
          <div class="added-children__inner">
            <div class="added-children-header">
              <div class="added-children-header__line"></div>
              <div
                class="added-children-header__trash"
                @click="deleteChildren(children.id)"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 3V4H4V6H5V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V6H20V4H15V3H9ZM7 6H17V19H7V6ZM9 8V17H11V8H9ZM13 8V17H15V8H13Z"
                    fill="#D20000"
                  />
                </svg>
              </div>
            </div>
            <div class="added-children-groups">
              <div class="added-children-group">
                <input
                  type="text"
                  class="input-form"
                  placeholder="Имя ребёнка"
                  v-model="children.name"
                  :class="{
                    'input-error':
                      validationChildren.value[index]?.name.$invalid &&
                      isSubbmited.value,
                  }"
                />
              </div>
              <div class="added-children-group added-children-group--message">
                <input
                  type="text"
                  class="input-form"
                  placeholder="Дата рождения"
                  v-mask="'##.##.####'"
                  v-model="children.birthday"
                  :class="{
                    'input-error':
                      validationChildren.value[index]?.birthday.$invalid &&
                      isSubbmited.value,
                  }"
                />
                <label class="input-error-message">Введена неверная дата</label>
              </div>
              <div class="added-children-group">
                <label class="checkbox-form">
                  <input
                    type="radio"
                    :name="children.id"
                    value="M"
                    checked
                    v-model="children.gender"
                  />
                  Мальчик
                </label>
                <label class="checkbox-form">
                  <input
                    type="radio"
                    :name="children.id"
                    value="F"
                    v-model="children.gender"
                  />
                  Девочка
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        class="add-children__action"
        @click="addNewChildren()"
      >
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.1937 11.6247H11.0799V16.7385H9.3753V11.6247H4.26147V9.92005H9.3753V4.80623H11.0799V9.92005H16.1937V11.6247Z"
            fill="#A4D65E"
          />
        </svg>

        Добавить
        {{ childrens.length > 0 ? childrens.length + 1 + "-го" : "" }} ребенка
      </button>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { mask } from "vue-the-mask";
class Children {
  constructor(id = "", gender = "", name = "", birthday = "") {
    this.id = id;
    this.name = name;
    this.birthday = birthday;
    this.gender = gender;
  }
}
export default {
  name: "AddChildrenControl",
  props: {
    modelValue: Array,
  },
  emits: ["update:modelValue"],
  inject: ["validationChildren", "isSubbmited"],
  methods: {
    addNewChildren() {
      this.childrens.push(new Children(uuidv4(), "M"));
    },
    deleteChildren(idChildren) {
      this.childrens.splice(
        this.childrens.findIndex((children) => children.id == idChildren),
        1
      );
    },
    changeChildren() {
      this.$emit("update:modelValue", this.childrens);
    },
  },
  directives: { mask },
  computed: {
    childrens: {
      get() {
        return this.modelValue;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.add {
  &-children {
    margin-top: 28px;
    margin-bottom: 40px;
    &__inner {
    }
    &__action {
      color: #a4d65e;
      display: flex;
      font-size: 16px;
      line-height: 22px;
      font-family: "Helvetica";
      border: none;
      background: transparent;
      align-items: center;
      cursor: pointer;
    }
  }
  &ed {
    &-children {
      display: flex;
      flex-direction: column;
      &:last-child {
        margin-bottom: 28px;
      }
      &__inner {
      }
      &-header {
        display: flex;
        align-items: center;
        margin-bottom: 17px;
        gap: 10px;
        &__line {
          border-top: 1px solid #c6c6c6;
          width: 100%;
        }
        &__trash {
          cursor: pointer;
        }
      }
      &s {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
      &-group {
        display: flex;
        gap: 20px;
        align-items: center;

        margin-bottom: 18px;
        &--message {
          flex-direction: column;
          gap: 0px;
          align-items: flex-start;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>