<template>
  <div class="form-registration-header">
    <div class="form-registration-header__inner content-padding">
      <div class="form-registration-header__logo">
        <i class="form-registration-header__svg" @click="closeRegistr"></i>
        <img src="@/assets/images/logoForm.svg" alt="Логотип сайта" />
      </div>
      <div class="form-registration-header__title">
        Регистрация в бонусной программе
      </div>
      <div class="form-registration-header__subtitle" @click="openBonusModal()">
        Условия бонусной программы
      </div>
    </div>
  </div>
  <ConditionalBonusModal v-model="showModalBonus" @cancel="closeBonusModal()" />
</template>

<script>
import router from "@/router";
import ConditionalBonusModal from "./modals/conditionalsBonusModal.vue";
export default {
  name: "HeaderForm",
  components: {
    ConditionalBonusModal,
  },
  data() {
    return {
      showModalBonus: false,
    };
  },
  methods: {
    closeRegistr(){
      router.push({path:'/'})
    },
    openBonusModal() {
      this.showModalBonus = true;
    },
    closeBonusModal() {
      this.showModalBonus = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-registration {
  &-header {
    padding: 12px 0px 28px;

    &__svg {
      cursor: pointer;
      display: block;
      top: 20px;
      left: 0;
      position: absolute;
      background-image: url('../assets/images/back-arrow.svg');
      background-repeat: no-repeat;
      width: 15px;
      height: 15px;
    }
    &__logo {
      position: relative;
      text-align: center;
      margin-bottom: 28px;
    }
    &__title {
      font-weight: 700;
      font-family: "a_FuturaRound";
      font-size: 24px;
      color: #41454b;
      line-height: 32px;
      margin-bottom: 9px;
    }
    &__subtitle {
      color: #005eb8;
      font-size: 16px;
      font-family: "Helvetica";
      line-height: 22px;
      cursor: pointer;
    }
  }
}
</style>