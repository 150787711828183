<template>
  <div class="form-registration">
    <div class="form-registration__inner">
      <HeaderForm/>
      <img
        class="
          form-registration__background-image
          form-registration__background-bottom
        "
        src="@/assets/images/bird-big.svg"
        alt="Птица"
      />
      <form class="form-registration-form" ref="formRegistration">
        <div class="form-registration-form__inner">
          <div class="form-registration-form-groups">
            <div class="form-registration-form-group">
              <input
                type="text"
                class="input-form"
                placeholder="Фамилия"
                v-model="infoForm.lastName"
                :class="{
                  'input-error': v$.infoForm.lastName.$invalid && attemptSubmit,
                }"
              />
            </div>
            <div class="form-registration-form-group">
              <input
                type="text"
                class="input-form"
                placeholder="Имя"
                v-model="infoForm.firstName"
                :class="{
                  'input-error':
                    v$.infoForm.firstName.$invalid && attemptSubmit,
                }"
              />
            </div>
            <div class="form-registration-form-group">
              <input
                type="text"
                class="input-form"
                v-model="infoForm.middleName"
                placeholder="Отчество"
                required
              />
              <span class="form-registration-form-group__not-required"
                >Необязательно</span
              >
            </div>
            <div class="form-registration-form-group">
              <input
                type="text"
                class="input-form"
                v-model="infoForm.email"
                :class="{
                  'input-error': v$.infoForm.email.$invalid && attemptSubmit,
                }"
                placeholder="E-mail"
                required
              />
              <span class="form-registration-form-group__not-required"
                >Необязательно</span
              >
            </div>
            <div class="form-registration-form-group">
              <input
                type="tel"
                class="input-form"
                v-model="infoForm.phone"
                placeholder="Телефон"
                masked="true"
                v-mask="'+7 (###) ###-##-##'"
                :class="{
                  'input-error': v$.infoForm.phone.$invalid && attemptSubmit,
                }"
              />
              <label class="input-error-message">{{ errorMessagePhone }}</label>
              <span class="form-registration-form-group__sublabel"
                >На этот номер придет код подтверждения</span
              >
            </div>
          </div>
          <AddChildrenControl v-model="infoForm.childrens" />
          <div class="form-registration-form-submit">
            <PrimaryButton @click="submitData()" :disabled="isLoading"
              >Зарегестрироваться</PrimaryButton
            >
            <div class="form-registration-form-submit__sublabel">
              Нажимая на кнопку, я соглашаюсь
              <span class="form-registration__link" @click="openPrivacyModal()">
                c условиями обработки персональных данных</span
              >
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ConfirmModal
    v-model="showModalConfirm"
    :formRegistration="infoForm"
    @opened="resetForm()"
  />
  <PrivacyPolicyModal
    v-model="showModalPrivacy"
    @cancel="showModalPrivacy = false"
  />
  <ConditionalBonusModal v-model="showModalBonus" />
</template>

<script>
import HeaderForm from "../components/HeaderForm.vue";
import PrimaryButton from "../components/UI/PrimaryButton.vue";
import AddChildrenControl from "../components/AddChildrenControl.vue";
import ConfirmModal from "../components/modals/confirmModal.vue";
import conditionalBonusModal from "../components/modals/conditionalsBonusModal.vue";
import PrivacyPolicyModal from "../components/modals/privacyPolicyModal.vue";
import { mask } from "vue-the-mask";
import { computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";
import { email, required, helpers, minLength } from "@vuelidate/validators";
import axios from "axios";
const INIT_FORM = {
  lastName: "",
  firstName: "",
  middleName: "",
  email: "",
  phone: "",
  childrens: [],
};
export default {
  components: {
    HeaderForm,
    PrimaryButton,
    AddChildrenControl,
    ConfirmModal,
    conditionalBonusModal,
    PrivacyPolicyModal,
  },
  directives: { mask },
  data() {
    return {
      showModalConfirm: false,
      showModalBonus: false,
      showModalPrivacy: false,
      isLoading: false,
      attemptSubmit: false,
      chkUsernameAvailabilityTimer: null,
      errorMessagePhone: "",
      infoForm: Object.assign({}, INIT_FORM),
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  methods: {
    submitData() {
      this.attemptSubmit = true;

      if (!this.v$.infoForm.$invalid) {
        this.isLoading = true;
        axios
          .post("/api/v1/registration/get-confirm-code", {
            phone: this.infoForm.phone,
          })
          .then(() => {
            this.showModalConfirm = true;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    openBonusModal() {
      this.showModalBonus = true;
    },
    openPrivacyModal() {
      this.showModalPrivacy = true;
    },
    resetForm() {
      this.attemptSubmit = false;
      this.$refs.formRegistration.reset();
      this.infoForm = Object.assign({}, INIT_FORM);
      this.infoForm.childrens = [];
      window.scrollTo(0, 0);
    },
  },
  provide() {
    return {
      validationChildren: computed(
        () => this.v$.infoForm?.childrens.$each.$response.$data
      ),
      isSubbmited: computed(() => this.attemptSubmit),
    };
  },
  validations() {
    return {
      infoForm: {
        lastName: { required },
        firstName: { required },
        email: { email },
        phone: {
          required,
          minLength(value) {
            if (value.replace(/[^\d]/g, "").length !== 11) {
              this.errorMessagePhone = "Неправильный номер телефона";
            }

            return value.replace(/[^\d]/g, "").length == 11;
          },
          isUnique: helpers.withAsync((value) => {
            let valueSubmit = value.replace(/[^\d]/g, "");
            if (valueSubmit.length !== 11) {
              return false;
            }
            return new Promise(
              (resolve) => {
                if (this.chkUsernameAvailabilityTimer) {
                  clearTimeout(this.chkUsernameAvailabilityTimer);
                  this.chkUsernameAvailabilityTimer = null;
                }
                this.chkUsernameAvailabilityTimer = setTimeout(() => {
                  axios
                    .get(`/api/v1/registration/check/${valueSubmit}`, {
                      headers: {
                        Authorization: "kzkapika",
                      },
                    })
                    .then(() => {
                      this.errorMessagePhone =
                        "Такой телефон уже зарегестрирован";
                      resolve(false);
                    })
                    .catch((e) => {
                      if (e.response.data.errors) {
                        this.errorMessageCode =
                          e.response.data.errors[0].message;
                        resolve(false);
                      } else {
                        resolve(true);
                      }
                    });
                });
              },
              { immediate: true }
            );
          }),
        },
        childrens: {
          $each: helpers.forEach({
            name: {
              required,
            },
            birthday: {
              required,
              minLength: minLength(10),
              minValue(val) {
                moment().local();
                let childhoodEdgeDate = new Date(
                  new Date().getFullYear() - 18,
                  new Date().getMonth(),
                  new Date().getDate()
                );
                return moment(val, "DD.MM.YYYY").toDate() > childhoodEdgeDate;
              },
              maxValue(val) {
                return new Date() > moment(val, "DD.MM.YYYY").toDate();
              },
            },
          }),
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.form-registration {
  background: #f3f5f9;
  &__link {
    transition: color 0.2s linear;
    cursor: pointer;
    &:hover {
      color: #a4d65e;
    }
  }
  &__inner {
    max-width: 500px;
    margin: 0 auto;
    height: 100%;
    position: relative;
  }
  &__background {
    &-image {
      position: absolute;
      z-index: -1;
    }
    &-bottom {
      bottom: 0;
    }
  }
  &-form {
    background: white;
    padding: 28px 16px 48px;
    border-radius: 24px 24px 0px 0px;
    height: 100%;
    &__inner {
    }
    &-group {
      margin-bottom: 18px;
      position: relative;
      &:last-child {
        margin-bottom: 0px;
      }
      &__sublabel {
        font-size: 14px;
        line-height: 19px;
        opacity: 0.5;
        font-family: "Helvetica";
        margin-top: 11px;
        display: block;
        color: #41454b;
      }
      &__not-required {
        display: none;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        font-family: "Helvetica";
        color: rgba(65, 69, 75, 0.5);
        font-size: 14px;
        line-height: 19px;
      }
      & > input:invalid + &__not-required {
        display: inline-block;
      }
    }
    &-submit {
      &__sublabel {
        font-size: 12px;
        line-height: 17px;
        color: #41454b;
        opacity: 0.5;
        display: block;
        margin-top: 20px;
        font-family: "Helvetica";
      }
    }
  }
}
</style>