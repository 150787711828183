<template>
  <button class="secondary-button" type="button" :disabled="disabled">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "SecondaryButton",
  props: ["disabled"],
};
</script>

<style lang="scss" scoped>
.secondary-button {
  transition: background 0.2s linear;
  -webkit-transition: background 0.2s linear;
  -moz-transition: background 0.2s linear;
  -ms-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  padding: 16px 32px;
  outline: none;
  background: #ffffff;
  border-radius: 32px;
  color: #a4d65e;
  border: 1px solid #a4d65e;
  cursor: pointer;
  width: max-content;
  &:disabled {
    border: 1px solid rgba(164, 214, 94, 0.5);
    color: rgba(164, 214, 94, 0.5);
  }
}
</style>