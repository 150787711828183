<template>
  <vue-final-modal
    v-bind="$attrs"
    classes="modal-container"
    @before-open="initModal()"
    content-class="modal-content"
  >
    <div class="conditional-bonus-modal">
      <div class="conditional-bonus-modal__inner">
        <div class="conditional-bonus-modal__label">
          Условия бонусной программы
        </div>
        <div class="conditional-bonus-modal__sublabel">
          Программа скидок и бонусов
        </div>
        <div class="conditional-bonus-modal__table-container">
          <table class="conditional-bonus-modal__table">
            <thead class="conditional-bonus-modal__table-header">
              <th>Клубная карта</th>
              <th v-if="false">Попрыгунчик</th>
              <th>Пушистик</th>
              <th>Ушастик</th>
              <th>Шустрик</th>
              <th>Счастливчик</th>
            </thead>
            <tbody class="conditional-bonus-modal__table-body">
              <tr>
                <td>Накопленная сумма покупок</td>
                <td v-if="false">до 10 000 ₸</td>
                <td>от 0 ₸</td>
                <td>от 70 000 ₸</td>
                <td>от 200 000 ₸</td>
                <td>от 500 000 ₸</td>
              </tr>
              <tr>
                <td>Процент начисляемых Кэшбэк Бонусов от стоимости покупки</td>
                <td v-if="false">0%</td>
                <td>5 %</td>
                <td>7 %</td>
                <td>10 %</td>
                <td>15 %</td>
              </tr>
              <tr>
                <td>
                  Промо бонус - День рождения, дополнительно начисляются бонусы
                </td>
                <td v-if="false">0</td>
                <td>2000</td>
                <td>5000</td>
                <td>7000</td>
                <td>10000</td>
              </tr>
              <tr>
                <td>
                  Промо бонус – Регистрация в личном кабинете с заполнением всех
                  полей
                </td>
                <td v-if="false">500</td>
                <td>1500</td>
                <td>1500</td>
                <td>1500</td>
                <td>1500</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="conditional-bonus-modal__sublabel">
          Начисление и списание бонусов
        </div>
        <ol class="conditional-bonus-modal__list">
          <li>
            Начисление бонусов происходит за любые покупки, кроме подарочных
            карт, услуг и подарков.
          </li>
          <li>
            База для начисления – сумма, фактически оплаченная после всех скидок
            и акций.
          </li>
          <li>
            Бонусы будут начислены через 14 дней после покупки, не считая день
            покупки.
          </li>
          <li>
            Срок жизни бонусов, начисленных за покупки – 365 дней. Спустя год с
            момента начисления бонусы сгорают.
          </li>
          <li>
            Срок жизни бонусов, подаренных ко Дню Рождения – Начисление идет за
            15 дней до дня рождения. Бонусы активны 30 дней с момента
            начисления. Далее бонусы сгорают.
          </li>
          <li>
            При регистрации в Личном кабинете с заполнением всех полей
            покупатель получает 1500 приветственных бонусов. Срок действия
            бонусов 3 месяцa с момента заполнения анкеты на сайте kapika.kz.
          </li>
          <li>
            В случае возврата товара израсходованные бонусы восстанавливаются на
            бонусном счете покупателя; начисленные за покупку бонусы
            аннулируются.
          </li>
          <!-- <li>
            Бонусы по Клубной карте не суммируются с действующими сезонными и
            другими скидками, если иное не предусмотрено правилами акций.
          </li> -->
          <li>
            Курс списания 1 бонус = 1 ₸. Скидка может составить не более 30%
            от стоимости товара, подлежащего оплате.
          </li>
          <li>
            Бонусы по Клубной карте действуют только в интернет магазине www.kapika.kz и в фирменных магазинах Kapika.
          </li>
          <!-- <li>
            Скидка по Клубной карте действует только в интернет магазине
            kapika.kz.
          </li> -->
          <li>
            Бонусы начисляются даже на товары со скидками.
          </li>
          <li>Клубная карта не имеет ограничений по сроку действия.</li>
          <li>
            Накопленная сумма покупок и накопленные бонусы переносятся на
            Клубную карту следующего уровня участия.
          </li>
        </ol>
        <div class="conditional-bonus-modal__sublabel">
          Бонусная программа для многодетных семей - скидка 10% на всё:
        </div>
        <ul class="conditional-bonus-modal__list">
          <li>Скидка на товар применяется в корзине.</li>
          <li>
            Cкидка действует при условии зарегистрированных в личном кабинете
            трех и более детей. Регистрация детей производится менеджером Kapika
            на основании свидетельства о Рождении ребенка (для регистрации
            только одного ребенка не требуется) Свидетельства необходимо выслать
            на почту shop@kapika.kz
          </li>
          <li>Скидка суммируется со всеми акциями и предложениями на сайте</li>
        </ul>
      </div>
      <div class="conditional-bonus-modal__close" @click="$emit('cancel')">
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="31" height="31" rx="15.5" fill="white" />
          <path
            opacity="0.5"
            d="M16.4728 15.5001L20.7984 11.1743C21.0672 10.9058 21.0672 10.4701 20.7984 10.2016C20.5297 9.93281 20.0945 9.93281 19.8257 10.2016L15.5001 14.5274L11.1743 10.2016C10.9055 9.93281 10.4703 9.93281 10.2016 10.2016C9.93281 10.4701 9.93281 10.9058 10.2016 11.1743L14.5274 15.5001L10.2016 19.8259C9.93281 20.0944 9.93281 20.5301 10.2016 20.7987C10.3359 20.9328 10.512 21 10.6879 21C10.8638 21 11.0399 20.9328 11.1743 20.7984L15.5001 16.4726L19.8257 20.7984C19.9601 20.9328 20.1362 21 20.3121 21C20.488 21 20.6641 20.9328 20.7984 20.7984C21.0672 20.5299 21.0672 20.0942 20.7984 19.8257L16.4728 15.5001Z"
            fill="#41454B"
          />
        </svg>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";
export default {
  name: "ConditionalBonusModal",
  components: {
    VueFinalModal,
  },
  inheritAttrs: false,
  emits: ["confirm", "cancel"],
  methods: {
    initModal() {},
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.modal-content) {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
}
.conditional-bonus {
  &-modal {
    background: white;
    height: 100%;
    position: relative;
    overflow-y: auto;
    &__close {
      right: 13px;
      position: fixed;
      top: 39px;
      cursor: pointer;
      line-height: 0;
    }
    &__label {
      font-weight: 700;
      font-family: "a_FuturaRound";
      color: #41454b;
      font-size: 20px;
      margin-bottom: 40px;
      line-height: 30px;
    }
    &__sublabel {
      font-weight: 700;
      font-size: 14px;
      color: #41454b;
      line-height: 18px;
      margin-bottom: 23px;
      font-family: "a_FuturaRound";
    }
    &__inner {
      padding: 53px 16px;
    }
    &__table {
      border-collapse: collapse;
      min-width: 560px;
      width: 100%;
      &::-webkit-scrollbar-thumb {
        width: 0;
      }
      &::-webkit-scrollbar {
        width: 0;
      }
      &-container {
        width: 100%;
        overflow-x: auto;
        margin-bottom: 45px;
      }
      &-header {
        th {
          font-size: 12px;
          color: #41454b;
          line-height: 17px;
          text-align: left;
          margin: 0;
          padding: 13px 0;
          padding-right: 20px;
          font-family: "Helvetica";
          &:first-child {
            white-space: initial;
            position: sticky;
            left: 0;
            background: white;
          }
        }
      }
      &-body {
        tr {
          td {
            font-size: 12px;
            font-family: "Helvetica";
            line-height: 17px;
            padding: 13px 13px 13px 0;
            border: none;
            white-space: nowrap;
            border-bottom: 0.5px solid #c6c6c6;
            color: #41454b;
            vertical-align: top;
            &:first-child {
              white-space: initial;
              position: sticky;
              left: 0;
              background: white;
            }
          }
        }
      }
    }
    &__list {
      font-weight: 400;
      font-size: 14px;
      padding-left: 16px;
      color: #41454b;
      line-height: 152.5%;
      font-family: "Helvetica";
      margin-bottom: 40px;
      li {
        &::marker {
          padding: 20px;
        }
      }
    }
  }
}
</style>
