<template>
  <div class="page-card">
    <div class="page-card__inner">
      <div class="page-card-header">
        <div class="page-card-header__inner content-padding">
          <div class="page-card-header__logo">
            <i class="page-card-header__svg" @click="exitProfile"></i>
            <img src="@/assets/images/logoForm.svg" alt="Логотип сайта" />
          </div>
          <div class="page-card-header__title">Программа скидок и бонусов</div>
          <div class="page-card-header__subtitle" @click="openBonusModal()">
            Условия бонусной программы
          </div>
        </div>
      </div>
      <div class="page-card-body" v-if="this.info">
        <swiper
          :pagination="pagination"
          :modules="modules"
          class="page-card-body__slider"
        >
          <swiper-slide>
            <div class="page-card-body__slider-item">
              <div class="page-card-body__slider-item-title">
                Ваша карта ({{ this.info.level.name }})
              </div>
              <div style="display: flex">
                <div class="page-card-body__slider-item-cashback">
                  кешбэк {{ this.info.level.cashbackPercent }}%
                </div>
                <div class="page-card-body__slider-item-cashback">
                  доступно {{ this.info.availableBonuses }}&nbsp;Б
                </div>
              </div>
              <div class="page-card-body__slider-item-progress">
                <div class="page-card-body__slider-item-progress-line">
                  <div
                    :style="{
                      width: (info.orderSum / info.nextLevelSum) * 100 + '%',
                    }"
                    class="page-card-body__slider-item-progress-line-process"
                  ></div>
                </div>
                <div class="page-card-body__slider-item-progress-container">
                  <div class="page-card-body__slider-item-progress-price">
                    0 ₸
                  </div>
                  <div
                    v-if="info.orderSum"
                    class="page-card-body__slider-item-progress-price"
                  >
                    {{ formatPrice(info.orderSum) }} ₸
                  </div>
                  <div class="page-card-body__slider-item-progress-price">
                    {{ formatPrice(info.nextLevelSum) }} ₸
                  </div>
                </div>
              </div>
              <div class="page-card-body__slider-item-progress-description">
                Совершите покупки еще на {{ formatPrice(info.toNextLevel) }} ₸
                для перехода на следующий уровень
              </div>
              <div class="page-card-body__slider-item-bonus-container">
                <div class="page-card-body__slider-item-bonus-title">
                  Дополнительные бонусы на день рождение ребенка
                </div>
                <div class="page-card-body__slider-item-bonus-text">
                  {{ formatPrice(info.level.birthdayBonus) }} Б
                </div>
              </div>
              <div
                class="page-card-body__slider-item-bonus-container"
                v-if="false"
              >
                <div class="page-card-body__slider-item-bonus-title">
                  Ваши бонусы <br />на счете
                </div>
                <div class="page-card-body__slider-item-bonus-text">
                  {{ info.availableBonuses }} Б
                </div>
              </div>
              <div class="page-card-body__slider-item-image">
                <i class="page-card-body__slider-item-image-check"></i>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide v-for="(level, index) in showLevels" :key="index">
            <div class="page-card-body__slider-item">
              <div class="page-card-body__slider-item-title">
                {{ level.name }}
              </div>
              <div
                class="page-card-body__slider-item-cashback"
                style="width: 120px"
              >
                кешбэк {{ level.cashbackPercent }}%
              </div>
              <div class="page-card-body__slider-item-svg">
                <i
                  class="page-card-body__slider-item-svg-rabbit"
                  v-bind:class="getItemClass(level.code)"
                ></i>
              </div>
              <div class="page-card-body__slider-item-bonus-container">
                <div class="page-card-body__slider-item-bonus-title">
                  Накопленная сумма покупок
                </div>
                <div class="page-card-body__slider-item-bonus-text">
                  от {{ formatPrice(level.sum) }} ₸
                </div>
              </div>
              <div class="page-card-body__slider-item-bonus-container">
                <div class="page-card-body__slider-item-bonus-title">
                  Дополнительные бонусы на день рождение ребенка
                </div>
                <div class="page-card-body__slider-item-bonus-text">
                  {{ formatPrice(level.birthdayBonus) }} Б
                </div>
              </div>
              <div class="page-card-body__slider-item-image">
                <i class="page-card-body__slider-item-image-lock"></i>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <button
          type="button"
          class="page-card__children-btn"
          @click="
            () => {
              showMyChildren = true;
            }
          "
        >
          <div class="page-card__children-btn-text">Мои дети</div>
          <ul class="page-card__children-btn-list" v-if="childrens.length != 0">
            <li
              class="page-card__children-btn-item page-card__children-btn-item-count"
              v-if="childrens.length > 3"
            >
              +{{ childrens.length - 2 }}
            </li>
            <li
              class="page-card__children-btn-item"
              v-show="childrens.length > 3 && index < 2"
              v-for="(children, index) of childrens"
              :key="children.id"
            >
              <img
                v-if="children.gender == 'M'"
                src="@/assets/images/children_boy.svg"
                alt="Boy"
              />
              <img
                v-if="children.gender == 'F'"
                src="@/assets/images/children_girl.svg"
                alt="Girl"
              />
            </li>
            <li
              class="page-card__children-btn-item"
              v-show="childrens.length < 4"
              v-for="children of childrens"
              :key="children.id"
            >
              <img
                v-if="children.gender == 'M'"
                src="@/assets/images/children_boy.svg"
                alt="Boy"
              />
              <img
                v-if="children.gender == 'F'"
                src="@/assets/images/children_girl.svg"
                alt="Girl"
              />
            </li>
          </ul>
          <div class="page-card__children-btn-add" v-if="childrens.length == 0">
            <img src="@/assets/images/children_add.svg" alt="AddChildren" />
          </div>
        </button>
        <div class="page-card-history">
          <div class="page-card-history__title">История начислений</div>
          <ul class="page-card-history__list" v-if="history.items.length">
            <li
              class="page-card-history__item"
              v-for="(item, idx) in history.items"
              :key="idx"
            >
              <div class="page-card-history__header">
                <div
                  v-if="
                    ['writeOff', 'expire', 'income', 'futureIncome'].includes(
                      item.type
                    )
                  "
                  class="page-card-history__bonus"
                  :class="{
                    'page-card-history__bonus--pink': [
                      'writeOff',
                      'expire',
                    ].includes(item.type),
                    'page-card-history__bonus--blue': [
                      'income',
                      'futureIncome',
                    ].includes(item.type),
                  }"
                >
                  {{ ["writeOff", "expire"].includes(item.type) ? "-" : "+" }}
                  {{ item.amount }} Б
                </div>
                <div></div>
                <div class="page-card-history__date">
                  {{ showDate(item.date) }}
                </div>
              </div>
              <div class="page-card-history__text">
                {{ item.description }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <ConditionalsBonusModal
    v-model="showModalBonus"
    @cancel="closeBonusModal()"
  />
  <MyChildrenModal v-model="showMyChildren" @cancel="closeMyChildren()">
  </MyChildrenModal>
</template>
<script>
import ConditionalsBonusModal from "@/components/modals/conditionalsBonusModal.vue";
import MyChildrenModal from "@/components/modals/myChildrenModal.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import axios from "axios";
import router from "@/router";
import moment from "moment";

export default {
  components: {
    MyChildrenModal,
    ConditionalsBonusModal,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      phone: "",
      showModalBonus: false,
      showMyChildren: false,
      childrens: [],
      levels: [],
      info: null,
      history: null,
    };
  },
  methods: {
    exitProfile() {
      axios.get("/api/v1/logout");
      this.$emit("exitProfile");
    },
    openBonusModal() {
      this.showModalBonus = true;
    },
    closeBonusModal() {
      this.showModalBonus = false;
    },
    closeMyChildren() {
      this.showMyChildren = false;
      axios
        .get("/api/v1/profile/children", {})
        .catch((e) => {
          console.log(e);
        })
        .then((resp) => {
          this.childrens = resp.data.children;
        });
    },
    formatPrice(val) {
      return val
        .toString()
        .replace(/[^0-9]/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    getItemClass(code) {
      switch (code) {
        case "level0":
          return {};
        case "level1":
          return { "page-card-body__slider-item-svg-rabbit--one": true };
        case "level2":
          return { "page-card-body__slider-item-svg-rabbit--two": true };
        case "level3":
          return { "page-card-body__slider-item-svg-rabbit--three": true };
        case "level4":
          return { "page-card-body__slider-item-svg-rabbit--four": true };
      }
      return {};
    },
    showDate(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },
  },
  computed: {
    showLevels() {
      let idx = this.levels.findIndex(
        (level) => level.id == this.info.level.id
      );
      return this.levels.filter((level, index) => index > idx);
    },
  },
  mounted() {
    axios
      .get("/api/v1/profile")
      .then((resp) => {
        let data = resp.data;
        this.levels = data.levels;
        this.info = data.info;
        this.history = data.history;
        console.log(data);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          console.log("Пользователь не авторизован");
          router.push({ path: "/" });
        } else if (e.response.status == 404) {
          console.log("Пользователь не зарегистрирован в программе лояльности");
          router.push({ path: "/" });
        }
      });

    axios
      .get("/api/v1/profile/children", {})
      .catch((e) => {
        console.log(e);
      })
      .then((resp) => {
        this.childrens = resp.data.children;
      });
  },
  setup() {
    return {
      pagination: {
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + "</span>";
        },
      },
      modules: [Pagination],
    };
  },
};
</script>
<style lang="scss">
.swiper-horizontal {
  padding-bottom: 30px;
}
.page-card {
  &__children {
    &-btn {
      width: calc(100% - 42px);
      border: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 21px 17px;
      margin: 0 21px;
      margin-top: 57px;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
      &-list {
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0;
      }

      &-item {
        position: relative;
        left: -10px;
        &:not(:nth-child(1)) {
          margin-left: -10px;
        }
        &-count {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          // margin-right: -5px;
          background-color: #f3f5f9;
          border-radius: 100%;
          width: 32px;
          height: 32px;
          color: #9b9da3;
          font-family: a_FuturaRound;
          font-size: 12px;
          font-weight: 400;
        }
      }
      &-add {
        display: contents;
      }
      &-text {
        color: #41454b;
        font-family: a_FuturaRound;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
      }
    }
  }
  &__inner {
    max-width: 500px;
    margin: 0 auto;
    height: 100%;
    position: relative;
  }

  background-color: #f3f5f9;
  &-header {
    padding: 12px 0px 28px;
    &__logo {
      position: relative;
      text-align: center;
      margin-bottom: 28px;
    }
    &__svg {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 9px;
      display: inline-block;
      background-image: url("../assets/images/exit.svg");
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
    }
    &__subtitle {
      color: #005eb8;
      font-size: 16px;
      font-family: "Helvetica";
      line-height: 22px;
      cursor: pointer;
    }
    &__title {
      font-weight: 700;
      font-family: "a_FuturaRound";
      font-size: 28px;
      color: #41454b;
      line-height: 32px;
      margin-bottom: 9px;
    }
  }

  &-history {
    padding: 69px 24px 0px;
    &__title {
      border-bottom: 1px solid #c6c6c6;
      padding-bottom: 23px;
      font-family: "a_FuturaRound";
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #41454b;
    }

    &__list {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 7px;
    }

    &__bonus {
      font-family: "a_FuturaRound";
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #ffffff;

      padding: 3.6129px 7.22581px;
      border-radius: 7.22581px;
      &--pink {
        background-color: #dea8dd;
      }

      &--blue {
        background-color: #005eb8;
      }
    }

    &__text {
      font-family: "Helvetica";
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #41454b;
    }

    &__date {
      font-family: "Helvetica";
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: #41454b;
      opacity: 0.5;
    }

    &__item {
      padding: 20px 0px;
      border-bottom: 1px solid #c6c6c6;
    }
  }

  &-body {
    background: white;
    padding: 28px 0px 48px;
    border-radius: 24px 24px 0px 0px;
    height: 100%;
    &__slider {
      //height: 470px;
      &-item {
        height: calc(100% - 94px);
        padding: 32px 15px 24px;
        margin: 0 auto;
        width: calc(100% - 72px);
        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 16px;

        &-svg {
          text-align: center;
          &-rabbit {
            margin-top: 4px;
            margin-bottom: 33px;
            display: inline-block;
            width: 66px;
            height: 83px;
            background-position: center;
            background-repeat: no-repeat;
            &--one {
              background-image: url("@/assets/images/rabbit_1.svg");
            }
            &--two {
              background-image: url("@/assets/images/rabbit_2.svg");
            }
            &--three {
              background-image: url("@/assets/images/rabbit_3.svg");
            }
            &--four {
              background-image: url("@/assets/images/rabbit_4.svg");
            }
          }
        }

        &-image {
          text-align: center;
          &-check {
            display: inline-block;
            background-image: url("@/assets/images/check.svg");
            background-repeat: no-repeat;
            background-position: center;
            width: 32px;
            height: 32px;
          }

          &-lock {
            display: inline-block;
            background-image: url("@/assets/images/lock.svg");
            background-repeat: no-repeat;
            background-position: center;
            width: 32px;
            height: 32px;
          }
        }

        &-bonus {
          &-container {
            margin-bottom: 25px;
            display: flex;
            justify-content: space-between;
          }

          &-title {
            margin-right: 29px;
            font-family: "Helvetica";
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            color: #41454b;
          }

          &-text {
            white-space: nowrap;
            font-family: "Helvetica";
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            color: #41454b;
          }
        }
        &-title {
          margin-bottom: 16px;
          text-align: center;
          font-family: "a_FuturaRound";
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          color: #005eb8;
        }
        &-cashback {
          //width: 113px;
          margin: 0 auto;
          text-align: center;
          font-family: "a_FuturaRound";
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          color: #ffffff;

          padding: 4px 8px;
          background: #005eb8;
          border-radius: 8px;
        }
        &-cashback:last-child {
          margin-left: 10px;
        }
        &-progress {
          margin-bottom: 24px;
          &-line {
            position: relative;
            margin-top: 70px;
            background-color: #e7edf6;
            height: 4px;
            margin-bottom: 4px;
            border-radius: 16px;
            &-process {
              position: absolute;
              left: 0;
              width: 20%;
              height: 4px;
              border-radius: 16px;
              background-color: #005eb8;
            }
          }
          &-container {
            display: flex;
            justify-content: space-between;
          }
          &-price {
            font-family: "Helvetica";
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #41454b;
          }

          &-description {
            margin-bottom: 26px;
            font-family: "Helvetica";
            font-weight: 400;
            font-size: 15px;
            line-height: 16px;
            color: #41454b;
            opacity: 0.5;
          }
        }
      }
    }
  }
  .swiper-pagination-bullet {
    width: 35.6px;
    height: 4px;
    background: #005eb8;
    border-radius: 8px;
    &:first-child {
      background-color: #4cbe5f;
    }
  }
}
</style>
