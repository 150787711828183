<template>
  <vue-final-modal
    v-bind="$attrs"
    classes="modal-container"
    @before-open="initModal()"
    @opened="$emit('opened')"
    @closed="resetForm()"
    content-class="modal-content"
  >
    <div
      class="modal-confirm"
      :class="{
        'modal-confirm--background-clearly': stateModal != 'registration',
      }"
    >
      <div class="modal-confirm__inner">
        <div class="modal-confirm-header">
          <img src="@/assets/images/logoForm.svg" alt="" />
        </div>
        <div
          class="modal-confirm-step modal-confirm-step__registration"
          v-if="stateModal == 'registration'"
        >
          <div class="modal-confirm-body">
            <div class="modal-confirm-body__label">Подтвердите регистрацию</div>
            <div class="modal-confirm-body__sublabel">
              На ваш номер телефона был отправлен код подтверждения
            </div>
            <form class="modal-confirm-body-group" ref="formCode">
              <div class="modal-confirm-body-group-inputs">
                <input
                  type="tel"
                  class="modal-confirm-body__input"
                  v-mask="'#'"
                  inputmode="numeric"
                  autocomplete="one-time-code"
                  v-for="i in countInputs"
                  :key="i"
                  :ref="setArrayInput"
                  @keyup="changeValueInput($event, i)"
                  v-model="codeValue[i - 1].value"
                  :class="{
                    'input-error':
                      v$?.codeValue.$each.$response.$data[i - 1].value
                        .$invalid && isSubmittedStatus,
                  }"
                />
              </div>
              <label
                class="label-error-message"
                v-if="errorMessageCode && isSubmittedStatus"
                >{{ errorMessageCode }}</label
              >
            </form>
            <PrimaryButton @click="sendCode()" :disabled="isLoading"
              >Подтвердить</PrimaryButton
            >
          </div>
          <div class="modal-confirm-footer">
            <div
              class="modal-confirm-footer-timer"
              :class="{ 'modal-confirm-footer-timer--hidden': timerEnded }"
            >
              Отправить код повторно можно будет через
              {{ timer }}
            </div>
            <SecondaryButton
              class="transition-initial-state"
              :class="{ 'transition-class-up': timerEnded }"
              :disabled="!timerEnded"
              @click="sendCodeAgain()"
              >Отправить еще раз</SecondaryButton
            >
          </div>
        </div>
        <div class="modal-confirm-step modal-confirm-step__success" v-else>
          <div class="modal-confirm-body__label">
            Регистрация в бонусной программе прошла успешно
          </div>
          <PrimaryButton @click="goHome()" :disabled="isLoading"
          >Перейти в личный кабинет</PrimaryButton>
        </div>
      </div>
      <div class="modal-confirm__background-clearly">
        <img
          class="position-23L-110T position"
          src="@/assets/images/bird-big.svg"
          alt=""
        />
        <img
          class="position-113L-127B position"
          src="@/assets/images/bird-mini.svg"
          alt=""
        />
        <img
          class="position-22R-0B position"
          src="@/assets/images/tree-big.svg"
          alt=""
        />
        <img
          class="position-96L-0B position"
          src="@/assets/images/tree-mini.svg"
          alt=""
        />
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";
import CountDown from "count-time-down";
import { useVuelidate } from "@vuelidate/core";
import { mask } from "vue-the-mask";
import PrimaryButton from "../UI/PrimaryButton.vue";
import SecondaryButton from "../UI/SecondaryButton.vue";
import { nextTick } from "vue";
import { required, helpers } from "@vuelidate/validators";
import axios from "axios";
import router from "@/router";
const STATE_MODAL = { REGISTRATION: "registration", SUCCESS: "success" };
export default {
  name: "confirmModal",
  components: {
    VueFinalModal,
    PrimaryButton,
    SecondaryButton,
  },
  props: ["formRegistration"],
  inheritAttrs: false,
  emits: ["confirm", "cancel", "opened"],
  directives: { mask },
  data() {
    return {
      isSubmittedStatus: false,
      timerLocale: null,
      timer: "1:00",
      countInputs: 4,
      isLoading: false,
      formLocalData: null,
      errorMessageCode: "",
      inputRefs: [],
      codeValue: new Array(4).fill(0).map(() => new Object({ value: "" })),
      stateModal: STATE_MODAL.REGISTRATION,
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      codeValue: {
        $each: helpers.forEach({
          value: {
            required,
          },
        }),
      },
    };
  },
  methods: {
    setArrayInput(el) {
      if (el && this.inputRefs.length < this.countInputs) {
        this.inputRefs.push(el);
      }
    },
    changeValueInput(event, index) {
      switch (event.target.value) {
        case "":
          if (index >= 2) this.inputRefs[index - 2].focus();
          break;
        default:
          if (index < this.countInputs) this.inputRefs[index].focus();
          break;
      }
    },
    sendCode() {
      this.isSubmittedStatus = true;

      if (!this.v$.codeValue.$invalid) {
        this.isLoading = true;
        this.errorMessageCode = "";
        let codeValue = this.codeValue.map((object) => object.value).join("");
        axios
          .post(
            "/api/v1/registration/register",
            Object.assign(
              {
                code: codeValue,
              },
              this.formLocalData
            )
          )
          .then(() => {
            this.stateModal = STATE_MODAL.SUCCESS;
          })
          .catch((e) => {
            if (e.response.data.errors)
              this.errorMessageCode = e.response.data.errors[0].message;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    sendCodeAgain() {
      axios
        .post("/api/v1/registration/resend-confirm-code", {
          phone: this.formLocalData?.phone.replace(/[^\d]/g, ""),
        })
        .finally(() => {
          this.startTimer();
          this.resetForm();
        });
    },
    initModal() {
      this.startTimer();
    },
    startTimer() {
      this.timerLocale = new CountDown(60000, { autoStart: true }, () => {
        this.timer = this.timerLocale.m + ":" + this.timerLocale.ss;
      });
    },
    resetForm() {
      this.isSubmittedStatus = false;
      this.errorMessageCode = null;
      this.stateModal = STATE_MODAL.REGISTRATION;
      nextTick(() => {
        this.$refs.formCode.reset();
        this.codeValue = this.codeValue.map(() => {
          return { value: "" };
        });
      });
    },
    goHome(){
      router.push({ path: "/" });
    }
  },
  computed: {
    timerEnded: {
      get() {
        return this.timer === "0:00";
      },
    },
  },
  mounted() {
    this.formLocalData = this.formRegistration;
  },
  beforeUpdate() {
    this.inputRefs = [];
  },
};
</script>

<style scoped lang="scss">
::v-deep(.modal-content) {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
}
.modal-confirm {
  background: white;
  padding: 0 54px;
  height: 100%;
  position: relative;
  &__inner {
    position: relative;
    z-index: 10;
    height: 100%;
  }
  &--background-clearly {
    background: #f3f5f9;
  }
  &__background-clearly {
    display: none;
    .position {
      position: absolute;
      animation: Down 1.5s ease-in-out 1;
      &-23L-110T {
        left: 23px;
        top: 110px;
        animation: Up 1.5s ease-in-out 1;
      }
      &-113L-127B {
        left: 113px;
        bottom: 127px;
        animation: Down 1.5s ease-in-out 1;
      }
      &-96L-0B {
        left: 96px;
        bottom: 0;
        animation: Down 1.5s ease-in-out 1;
      }
      &-22R-0B {
        right: 22px;
        bottom: 0;
        animation: Down 1.5s ease-in-out 1;
      }
    }
  }
  &--background-clearly > &__background-clearly {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    display: block;
  }
  &-step {
    &__success {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      text-align: center;
      animation: Up 1.5s ease-in-out 1;
    }
  }
  &-header {
    text-align: center;
    padding: 23px 0;
  }
  &-body {
    margin-bottom: 68px;
    text-align: center;
    &__label {
      font-family: "a_FuturaRound";
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 10px;
      color: #41454b;
    }
    &__sublabel {
      font-size: 14px;
      line-height: 19px;

      font-family: "Helvetica";
      margin-bottom: 25px;
      color: rgba(65, 69, 75, 0.5);
    }
    &-group {
      &-inputs {
        display: flex;
        gap: 10px;
        margin: 0 auto;
        max-width: 400px;
      }
      &__error {
        color: #d65e5e;
        font-family: "a_FuturaRound";
        font-size: 12px;
        line-height: 15px;
        margin-top: 13px;
      }
      margin-bottom: 30px;
    }
    &__input {
      background: #f3f5f9;
      border: 1px solid #f3f5f9;
      border-radius: 12px;
      width: 100%;
      font-size: 16px;
      line-height: 16px;
      color: #000000;
      transition: all 0.2s linear;
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      -ms-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      padding: 18px 5px;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      text-align: center;
      &:focus-visible {
        outline: none;
      }
    }
  }
  &-footer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    &-timer {
      font-size: 14px;
      line-height: 19px;
      color: #5c5c5c;
      font-family: "Helvetica";
      text-align: center;
      opacity: 1;
      visibility: visible;
      transition: all 0.3s linear;
      &--hidden {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}
.transition {
  &-initial-state {
    transform: translateY(0px);
    transition: all 0.5s ease-in-out;
  }
  &-class-up {
    transform: translateY(-120%);
  }
}
@keyframes Up {
  from {
    top: 60%;
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes Down {
  from {
    bottom: 30%;
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
