<template>
  <vue-final-modal
    v-bind="$attrs"
    classes="modal-container"
    @before-open="initModal()"
    content-class="modal-content"
  >
    <form class="my-children" ref="myChildrenForm">
      <div class="my-children__inner">
        <div class="my-children__header">
          <div class="content-padding">
            <div class="my-children__header-logo">
              <img
                class="my-children__header-logo--back"
                src="@/assets/images/arrow_back.svg"
                alt="Back"
                @click="$emit('cancel')"
              />
              <img src="@/assets/images/logoForm.svg" alt="Логотип сайта" />
            </div>
            <div class="my-children__header-title">Мои дети</div>
            <div class="my-children__header-subtitle" @click="openBonusModal()">
              Условия бонусной программы
            </div>
          </div>
        </div>
        <div class="my-children__body">
          <ul class="my-children__body-list" v-if="childrens.length != 0">
            <li
              v-for="children of childrens"
              :key="children.id"
              class="my-children__body-item"
            >
              <div class="my-children__body-item-left">
                <img
                  v-if="children.gender == 'M'"
                  src="@/assets/images/children_boy.svg"
                  alt="Boy"
                />
                <img
                  v-if="children.gender == 'F'"
                  src="@/assets/images/children_girl.svg"
                  alt="Girl"
                />
                <span class="my-children__body-item-name">{{
                  children.name
                }}</span>
              </div>
              <div class="my-children__body-item-right">
                {{ newTime(children.birthday) }}
              </div>
            </li>
          </ul>

          <button
            v-if="!addChildren"
            @click="showAddChildren"
            type="button"
            class="my-children__body-btn"
          >
            <img src="@/assets/images/add_plus.svg" alt="Add" />
            <span>Добавить ребенка</span>
          </button>

          <div class="my-children__body-add" v-if="addChildren">
            <div class="my-children__body-add-remove">
              <span class="my-children__body-add-remove-line"></span>
              <img
                @click="closeChild"
                src="@/assets/images/remove_child.svg"
                alt="Remove"
              />
            </div>
            <div class="added-children-groups">
              <div class="added-children-group">
                <input
                  type="text"
                  class="input-form"
                  placeholder="Имя ребёнка"
                  v-model="childrenValidations.name"
                  :class="{
                    'input-error':
                      v$.childrenValidations.name.$invalid && isSubmitData,
                  }"
                />
              </div>
              <div class="added-children-group added-children-group--message">
                <input
                  type="text"
                  class="input-form"
                  placeholder="Дата рождения"
                  v-mask="'##.##.####'"
                  v-model="childrenValidations.birthday"
                  :class="{
                    'input-error':
                      v$.childrenValidations.birthday.$invalid && isSubmitData,
                  }"
                />
                <label class="input-error-message">Введена неверная дата</label>
              </div>
              <div class="added-children-group added-children-group-radio">
                <label class="checkbox-form">
                  <input
                    type="radio"
                    :name="childrenValidations.id"
                    value="M"
                    checked
                    v-model="childrenValidations.gender"
                  />
                  Мальчик
                </label>
                <label class="checkbox-form">
                  <input
                    type="radio"
                    :name="childrenValidations.id"
                    value="F"
                    v-model="childrenValidations.gender"
                  />
                  Девочка
                </label>
              </div>
              <button
                type="button"
                class="my-children__body-add-btn"
                @click="submitData"
              >
                Добавить ребенка
              </button>
            </div>
          </div>

          <div class="my-children__body-text">
            Нажимая на кнопку, я соглашаюсь с условиями обработки персональных
            данных
          </div>
        </div>
      </div>
    </form>
  </vue-final-modal>
  <ConditionalsBonusModal
    v-model="showModalBonus"
    @cancel="closeBonusModal()"
  />
</template>
<script>
import ConditionalsBonusModal from "@/components/modals/conditionalsBonusModal.vue";
import { VueFinalModal } from "vue-final-modal";
import { v4 as uuidv4 } from "uuid";
import { required, minLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";
import { mask } from "vue-the-mask";
import axios from "axios";
class Children {
  constructor(id = "", gender = "", name = "", birthday = "") {
    this.id = id;
    this.name = name;
    this.birthday = birthday;
    this.gender = gender;
  }
}
const INIT_FORM = new Children();
export default {
  name: "MyChildrenModal",
  components: {
    VueFinalModal,
    ConditionalsBonusModal,
  },
  data() {
    return {
      addChildren: false,
      childrens: [],
      showModalBonus: false,
      isSubmitData: false,
      childrenValidations: Object.assign({}, INIT_FORM),
    };
  },
  setup: () => ({ v$: useVuelidate() }),

  directives: { mask },
  inheritAttrs: false,
  emits: ["confirm", "cancel"],
  methods: {
    newTime(birthday) {
      return moment(birthday).locale("ru").format("DD MMMM YYYY");
    },
    showAddChildren() {
      this.addChildren = true;
      this.childrenValidations.id = uuidv4();
      this.childrenValidations.gender = "M";
    },
    closeChild() {
      this.addChildren = false;
      this.$refs.myChildrenForm.reset();
      this.childrenValidations = Object.assign({}, INIT_FORM);
    },
    initModal() {
      axios
        .get("/api/v1/profile/children", {})
        .catch((e) => {
          console.log(e);
        })
        .then((resp) => {
          this.childrens = resp.data.children;
        });
    },
    openBonusModal() {
      this.showModalBonus = true;
    },
    closeBonusModal() {
      this.showModalBonus = false;
    },
    submitData() {
      this.isSubmitData = true;
      const date = new Date(moment(this.childrenValidations.birthday, 'DD-MM-YYYY').format('YYYY-MM-DD')).toISOString();
      if (!this.v$.childrenValidations.$invalid) {
        axios
          .post("/api/v1/profile/children/add", {
            name: this.childrenValidations.name,
            birthday: date,
            gender: this.childrenValidations.gender,
          })
          .catch((e) => {
            console.log(e);
          })
          .then((resp) => {
            console.log(resp.data);
            this.isSubmitData = false;
            this.addChildren = false;
            this.$refs.myChildrenForm.reset();
            this.childrenValidations = Object.assign({}, INIT_FORM);
            axios
              .get("/api/v1/profile/children", {})
              .catch((e) => {
                console.log(e);
              })
              .then((resp) => {
                this.childrens = resp.data.children;
              });
          });
      }
    },
  },
  validations() {
    return {
      childrenValidations: {
        name: {
          required,
        },
        birthday: {
          required,
          minLength: minLength(10),
          minValue(val) {
            moment().local();
            let childhoodEdgeDate = new Date(
              new Date().getFullYear() - 18,
              new Date().getMonth(),
              new Date().getDate()
            );
            return moment(val, "DD.MM.YYYY").toDate() > childhoodEdgeDate;
          },
          maxValue(val) {
            return new Date() > moment(val, "DD.MM.YYYY").toDate();
          },
        },
      },
    };
  },
};
</script>
<style lang="scss" scoped>
::v-deep(.modal-content) {
  height: 100%;
}
.my-children {
  height: 100%;
  background-color: #f3f5f9;
  .added-children-group {
    margin-bottom: 16px;
  }

  .added-children-group-radio {
    display: flex;
  }

  .checkbox-form {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  &__inner {
    max-width: 500px;
    margin: 0 auto;
    height: 100%;
    position: relative;
  }
  &__header {
    padding: 12px 0px 28px;

    &-logo {
      margin-bottom: 28px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &--back {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &-title {
      margin-bottom: 9px;
      color: #41454b;
      font-family: a_FuturaRound;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
    }

    &-subtitle {
      color: #005eb8;
      font-family: Helvetica;
      font-size: 16px;
      font-weight: 400;
    }
  }
  &__body {
    background: white;
    padding: 28px 20px 48px 12px;
    border-radius: 24px 24px 0px 0px;
    height: 100%;

    &-item {
      border-radius: 12px;
      background: #f3f5f9;
      padding: 9px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 18px;
      }
      &-right {
        color: #41454b;
        font-family: Helvetica;
        font-size: 12px;
        font-weight: 400;
        opacity: 0.5;
      }
      &-left {
        display: flex;
        align-items: center;
        color:#41454b;
        font-family: a_FuturaRound;
        font-size: 16px;
        font-weight: 400;
        line-height: 32px;
        img {
          margin-right: 12px;
        }
      }
    }

    &-add {
      &-remove {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        &-line {
          margin-right: 10px;
          width: 100%;
          height: 1px;
          background-color: #c6c6c6;
        }
      }
      &-btn {
        margin: 0 auto;
        margin-bottom: 20px;
        color: #fff;
        font-family: a_FuturaRound;
        font-size: 16px;
        font-weight: 700;
        border-radius: 32px;
        border: none;
        background: #a4d65e;
        display: flex;
        padding: 16px 32px;
        justify-content: center;
      }
    }
    &-list {
      margin: 0;
      padding: 0;
      list-style: none;
      margin-bottom: 28px;
    }

    &-btn {
      padding: 0;
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      background-color: inherit;
      border: none;
      color: #a4d65e;
      font-family: Helvetica;
      font-size: 16px;
      font-weight: 400;
      img {
        margin-right: 7px;
      }
    }
    &-text {
      color: #41454b;
      font-family: Helvetica;
      font-size: 12px;
      font-weight: 400;
      opacity: 0.5;
    }
  }
}
</style>
