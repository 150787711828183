<template>
  <vue-final-modal
    v-bind="$attrs"
    classes="modal-container"
    @before-open="initModal()"
    content-class="modal-content"
  >
    <div class="privacy-policy-modal">
      <div class="privacy-policy-modal__inner">
        <div class="privacy-policy-modal__label">
          Политика конфиденциальности
        </div>
        <ol class="privacy-policy-modal__list-out">
          <li class="privacy-policy-modal__list-out-element">
            <div class="privacy-policy-modal__list-out-element__title">
              Термины и определения
            </div>
            <ul
              class="
                privacy-policy-modal__list-in
                privacy-policy-modal__list-in--dash
              "
            >
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Сайт - ресурс, расположенный в сети Интернет по адресу
                  <a class="privacy-policy-modal__link" href="www.kapika.ru"
                    >www.kapika.ru</a
                  >
                  и являющийся собственностью ООО «Фабрика обуви». Сайт – это
                  ресурс, который может содержать или непосредственно содержит
                  файлы, информацию, программное обеспечение, иллюстрации,
                  фотографии и аналогичные файлы, которые являются объектами
                  авторского права, товарного знака или знака обслуживания, или
                  которые подпадают под действие других тождественных или
                  смежных прав ООО «Фабрика обуви» и Пользователей Сайта.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  ООО «Фабрика обуви» - юридическое лицо, созданное в
                  соответствии с законодательством РФ, осуществляющее свою
                  деятельность, в том числе, посредством Интернет-ресурса
                  (Интернет-Магазина)
                  <a class="privacy-policy-modal__link" href="www.kapika.ru"
                    >www.kapika.ru</a
                  >.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Интернет-магазин – площадка для осуществления продажи Товаров
                  от имени ООО «Фабрика обуви», расположенная на Сайте
                  <a class="privacy-policy-modal__link" href="www.kapika.ru"
                    >www.kapika.ru</a
                  >.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Пользователь - физическое лицо, использующее сервисы Сайта,
                  отдельные функции Сайта.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Покупатель - гражданин, имеющий намерение приобрести Товары
                  исключительно для личных, семейных, домашних и иных нужд, не
                  связанных с осуществлением предпринимательской деятельности.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Сервисы Сайта – все услуги, доступные для использования на
                  Сайте
                  <a class="privacy-policy-modal__link" href="www.kapika.ru"
                    >www.kapika.ru</a
                  >.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Персональная информация – информация, которую пользователь
                  предоставляет о себе самостоятельно при регистрации (создании
                  учётной записи), оформлении Заказа на Сайте или в процессе
                  использования Сервисов, включая, но не ограничиваясь,
                  персональные данные пользователя.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Личное Пространство – персонализированный интерфейс Сайта с
                  набором пользовательских инструментов для осуществления
                  покупок из каталога Интернет-Магазина на Сайте, а также для
                  пользования персонализированными Сервисами Сайта.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Оператор персональных данных - ООО «Фабрика обуви». Адрес
                  места нахождения: 125599, г. Москва, улица Маршала Федоренко,
                  д. 3 стр.1.
                </div>
              </li>
            </ul>
          </li>
          <li class="privacy-policy-modal__list-out-element">
            <div class="privacy-policy-modal__list-out-element__title">
              Общие положения:
            </div>
            <ol class="privacy-policy-modal__list-in">
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Настоящая Политика конфиденциальности (далее – Политика)
                  является публичной офертой и определяет условия использования
                  Пользователями материалов и сервисов, размещенных на сайте в
                  сети Интернет по адресу:
                  <a class="privacy-policy-modal__link" href="www.kapika.ru"
                    >www.kapika.ru</a
                  >
                  (далее - Сайт). Сайт является собственностью ООО «Фабрика
                  обуви» (ОГРН 1037721025572) и создан в целях знакомства
                  потенциального покупателя с ООО «Фабрика обуви», с
                  ассортиментом продукции, повышения потребительского спроса на
                  товары, на котором возможно совершить покупку.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Настоящая Политика действует в отношении всей информации,
                  которую ООО «Фабрика обуви» (ОГРН 1037721025572) может
                  получить о пользователе во время использования им сайта,
                  сервисов, служб, программ и продуктов ООО «Фабрика обуви»
                  (далее – Сервисы). Все существующие на данный момент сервисы,
                  а также любое развитие их и/или добавление новых является
                  предметом настоящей Политики.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Политика может быть изменена ООО «Фабрика обуви» в
                  одностороннем порядке без уведомления Пользователя. Новая
                  редакция Политики вступает в силу с момента ее опубликования
                  на Сайте, если иное не предусмотрено условиями настоящей
                  Политики.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Пользователь/Покупатель соглашается со всеми условиями
                  настоящей Политики с момента Регистрации заказа на Сайте.
                </div>
              </li>
            </ol>
          </li>
          <li class="privacy-policy-modal__list-out-element">
            <div class="privacy-policy-modal__list-out-element__title">
              Право использования Материалов/Изображений
            </div>
            <ol class="privacy-policy-modal__list-in">
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Все объекты, доступные при помощи Сайта, в том числе элементы
                  дизайна, текст, графические изображения, иллюстрации, видео,
                  программы, базы данных, и другие объекты, а также любой
                  контент, размещенный на сервисе, являются объектами
                  исключительных прав ООО «Фабрика обуви».
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Использование контента, а также каких-либо иных элементов
                  сервиса возможно только в рамках функционала, предлагаемого
                  сервисом. Никакие элементы содержания сервиса, а также любой
                  контент, размещенный на сервисе, не могут быть использованы
                  иным образом без предварительного разрешения правообладателя.
                  Под использованием подразумеваются, в том числе:
                  воспроизведение, копирование, переработка, распространение на
                  любой основе, отображение во фрейме и т.д. Исключение
                  составляют случаи, прямо предусмотренные законодательством РФ.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Использование Пользователем элементов содержания сервисов, а
                  также любого контента для личного некоммерческого
                  использования, допускается при условии сохранения всех знаков
                  охраны авторского права, смежных прав, товарных знаков, других
                  уведомлений об авторстве, сохранения имени (или псевдонима)
                  автора/наименования правообладателя в неизменном виде,
                  сохранении соответствующего объекта в неизменном виде.
                  Исключение составляют случаи, прямо предусмотренные
                  законодательством РФ.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Пользователю запрещено воспроизводить, распространять,
                  перерабатывать в коммерческих или некоммерческих целях
                  элементы Сайта, являющиеся объектами авторских прав при
                  отсутствии разрешения соответствующих правообладателей на
                  совершение данных действий.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Ответственность за неправомочное копирование Материалов и
                  Изображений, за неправомерно использование Материалов и
                  Изображений, опубликованных на Сайте, несут лица, совершившие
                  данное деяние.
                </div>
              </li>
            </ol>
          </li>
          <li class="privacy-policy-modal__list-out-element">
            <div class="privacy-policy-modal__list-out-element__title">
              Гарантии и ответственность Пользователя
            </div>
            <span class="privacy-policy-modal__list-out__remark"
              >Пользователь не должен использовать сервисы и Сайт для:</span
            >
            <ol class="privacy-policy-modal__list-in">
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  совершения действий, приводящих к нарушениям нормальной работы
                  cети.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  загрузка материалов, которые являются незаконными,
                  вредоносными, угрожающими, содержащих вирусы или другие
                  компьютерные коды, файлы или программы, предназначенные для
                  нарушения, уничтожения либо ограничения функциональности
                  любого компьютерного или телекоммуникационного оборудования
                  или программ, для осуществления несанкционированного доступа;
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  загрузка Фото, оскорбляющими нравственность, клеветническими,
                  нарушающими авторские права, пропагандирующими ненависть и/или
                  дискриминацию людей по расовому, этническому, половому,
                  социальному и иным признакам нарушения прав несовершеннолетних
                  лиц и/или причинение им вреда в любой форме;
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  ущемления прав меньшинств;
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  загрузка Фото, содержащей материалы порнографического
                  характера и пр., что влечет или может повлечь за собой
                  нарушение действующего законодательства РФ.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Пользователь самостоятельно несет ответственность и считает
                  ООО «Фабрика обуви» свободным от возмещения ущерба/убытков по
                  претензиям третьих лиц, выдвинутых в отношении
                  Материалов/Изображений и их содержания. В случае предъявления
                  претензий к ООО «Фабрика обуви» в отношении
                  Материалов/Изображений и их содержания со стороны любых
                  третьих лиц, Пользователь обязуется урегулировать такие
                  претензии и/или иски самостоятельно и за свой счет либо
                  возместить ООО «Фабрика обуви» убытки, вызванные предъявлением
                  таких претензий в полном объеме.
                </div>
              </li>
            </ol>
            <span class="privacy-policy-modal__list-out__remark"
              >Пользователь обязуется возместить имущественные потери ООО
              «Фабрика обуви» в порядке ст. 406.1 ГК РФ в размере сумм,
              направленных на возмещении понесенных убытков третьим лицам, чьи
              интеллектуальные права были нарушены и/или нарушено право,
              предусмотренное ст. 152.1 ГК РФ, а также Пользователь обязуется
              возместить судебные расходы, а также расходы, направленные на
              подготовку, ведение судебных дел, в том числе при привлечении
              консультантов (адвокатов), в т.ч. занимающих ведущие позиции в
              своей области профессиональной деятельности, а также в размере
              сумм, направленных на исполнение судебных решений, сумм,
              направленных на уплату санкций, неустоек и штрафов по
              соответствующим договорам.</span
            >
            <span class="privacy-policy-modal__list-out__remark"
              >Пользователь не имеет права передавать, продавать, публиковать,
              перемещать, воспроизводить, модифицировать или переделывать
              материалы Сайта или использовать их каким-либо иным подобным
              образом, как частично, так и в целом, за исключением письменного
              разрешения ООО «Фабрика обуви» на указанные действия.</span
            >
            <span class="privacy-policy-modal__list-out__remark"
              >Пользователь самостоятельно несет ответственность перед третьими
              лицами за свои действия, связанные с использованием сервиса, в том
              числе, если такие действия приведут к нарушению прав и законных
              интересов третьих лиц, а также за соблюдение законодательства при
              использовании сервиса.</span
            >
            <span class="privacy-policy-modal__list-out__remark"
              >ООО «Фабрика обуви» вправе предпринимать незапрещенные законом
              меры для защиты собственных интеллектуальных прав в отношении
              Сайта/Сервисов.</span
            >
            <span class="privacy-policy-modal__list-out__remark"
              >Действие настоящей Политики в части гарантий и ответственности
              Пользователя бессрочно.</span
            >
          </li>
          <li class="privacy-policy-modal__list-out-element">
            <div class="privacy-policy-modal__list-out-element__title">
              Регистрация Пользователя. Учетная запись Пользователя
            </div>
            <ol class="privacy-policy-modal__list-in">
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Для регистрации, в результате которой для Пользователя будет
                  создана уникальная учетная запись, Пользователь обязуется
                  предоставить достоверную и полную информацию о себе по
                  вопросам, предлагаемым в форме регистрации, и поддерживать эту
                  информацию в актуальном состоянии. Если Пользователь
                  предоставляет неверную информацию или у ООО «Фабрика обуви»
                  есть основания полагать, что предоставленная Пользователем
                  информация неполна или недостоверна, ООО «Фабрика обуви» имеет
                  право по своему усмотрению заблокировать либо удалить учетную
                  запись Пользователя и отказать Пользователю в использовании
                  своих сервисов (либо их отдельных функций).
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  В случае, когда данные, указанные при регистрации, не
                  позволяют идентифицировать пользователя, ООО «Фабрика обуви»
                  вправе отказать Пользователю в доступе к учетной записи и
                  использовании сервисов Сайта.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  При регистрации Пользователь самостоятельно выбирает себе
                  логин (уникальное символьное имя учетной записи Пользователя)
                  и пароль для доступа к учетной записи. ООО «Фабрика обуви»
                  вправе запретить использование определенных логинов, а также
                  устанавливать требования к логину и паролю (длина, допустимые
                  символы и т.д.).
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Пользователь самостоятельно несет ответственность за
                  безопасность (устойчивость к угадыванию) выбранных им средств
                  для доступа к учетной записи, а также самостоятельно
                  обеспечивает их конфиденциальность. Пользователь
                  самостоятельно несет ответственность за все действия (а также
                  их последствия) в рамках или с использованием сервисов Сайта
                  под учетной записью Пользователя, включая случаи добровольной
                  передачи Пользователем данных для доступа к учетной записи
                  Пользователя третьим лицам на любых условиях (в том числе по
                  договорам или соглашениям). При этом все действия в рамках или
                  с использованием сервисов Сайта под учетной записью
                  Пользователя считаются произведенными самим Пользователем, за
                  исключением случаев, когда Пользователь, в порядке,
                  предусмотренном п. 5.5. настоящего Соглашения, уведомил ООО
                  «Фабрика обуви» о несанкционированном доступе к сервисам Сайта
                  с использованием учетной записи Пользователя и/или о любом
                  нарушении (подозрениях о нарушении) конфиденциальности своих
                  средств доступа к учетной записи.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Пользователь обязан немедленно уведомить ООО «Фабрика обуви» о
                  любом случае несанкционированного (не разрешенного
                  Пользователем) доступа к сервисам Сайта с использованием
                  учетной записи Пользователя и/или о любом нарушении
                  (подозрениях о нарушении) конфиденциальности своих средств
                  доступа к учетной записи.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  ООО «Фабрика обуви» вправе заблокировать или удалить учетную
                  запись Пользователя, а также запретить доступ с использованием
                  какой-либо учетной записи к определенным сервисам Сайта без
                  объяснения причин, в том числе в случае нарушения
                  Пользователем условий Соглашения или действующего
                  законодательства РФ, а также в случае неиспользования
                  Пользователем Сайта, учетной записи более 24 месяцев.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Пользователь вправе в любой момент удалить свою учетную запись
                  на Сайте, обратившись в Службу клиентской поддержки ООО
                  «Фабрика обуви» или при наличии соответствующей функции
                  прекратить ее действие.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  С момента удаления учетной записи восстановление учетной
                  записи, а равно доступов к сервисам Сайта с использованием
                  этой учетной записи - невозможны, а логин будет доступен для
                  использования другим пользователям.
                </div>
              </li>
            </ol>
          </li>
          <li class="privacy-policy-modal__list-out-element">
            <div class="privacy-policy-modal__list-out-element__title">
              Цели сбора и обработки персональной информации пользователей:
            </div>
            <ol class="privacy-policy-modal__list-in">
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  ООО «Фабрика обуви» собирает, обрабатывает и хранит только ту
                  персональную информацию, которая необходима для предоставления
                  Сервисов и/или приобретения Пользователем Товара из каталога
                  Интернет-Магазина на Сайте.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Персональную информацию Пользователя ООО «Фабрика обуви»
                  использует в следующих целях:
                  <ol class="privacy-policy-modal__list-in" start="2">
                    <li class="privacy-policy-modal__list-in-element">
                      <div class="privacy-policy-modal__list-in-element__body">
                        идентификации стороны в рамках договоров с ООО «Фабрика
                        обуви»;
                      </div>
                    </li>
                    <li class="privacy-policy-modal__list-in-element">
                      <div class="privacy-policy-modal__list-in-element__body">
                        обработки и получения от Пользователя платежей;
                      </div>
                    </li>
                    <li class="privacy-policy-modal__list-in-element">
                      <div class="privacy-policy-modal__list-in-element__body">
                        доставки товара Пользователю;
                      </div>
                    </li>
                    <li class="privacy-policy-modal__list-in-element">
                      <div class="privacy-policy-modal__list-in-element__body">
                        предоставления Пользователю эффективной клиентской
                        поддержки;
                      </div>
                    </li>
                    <li class="privacy-policy-modal__list-in-element">
                      <div class="privacy-policy-modal__list-in-element__body">
                        предоставления пользователю персонализированных
                        Сервисов;
                      </div>
                    </li>
                    <li class="privacy-policy-modal__list-in-element">
                      <div class="privacy-policy-modal__list-in-element__body">
                        связи с пользованием, в том числе направление
                        уведомлений, запросов и информации, касающихся
                        использования Сервисов, оказания услуг, а также
                        обработка запросов и заявок от пользователя;
                      </div>
                    </li>
                    <li class="privacy-policy-modal__list-in-element">
                      <div class="privacy-policy-modal__list-in-element__body">
                        улучшение качества Сервисов, удобства их использования,
                        разработка новых Сервисов и услуг;
                      </div>
                    </li>
                    <li class="privacy-policy-modal__list-in-element">
                      <div class="privacy-policy-modal__list-in-element__body">
                        информирования Пользователя о проводимых ООО «Фабрика
                        обуви» мероприятиях и акциях;
                      </div>
                    </li>
                    <li class="privacy-policy-modal__list-in-element">
                      <div class="privacy-policy-modal__list-in-element__body">
                        проведение статистических и иных исследований на основе
                        обезличенных данных.
                      </div>
                    </li>
                    <li class="privacy-policy-modal__list-in-element">
                      <div class="privacy-policy-modal__list-in-element__body">
                        для отсылки новостных сообщений 1-2 раза в неделю.
                      </div>
                    </li>
                  </ol>
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  ООО «Фабрика обуви», в силу специфики способа получения
                  информации, не проверяет достоверность предоставленной
                  Пользователем персональной информации и не осуществляет
                  контроль ее актуальности. Однако ООО «Фабрика обуви» исходит
                  из того, что Пользователь предоставляет достоверную и
                  персональную информацию по вопросам, предлагаемым в форме
                  регистрации, и поддерживает эту информацию в актуальном
                  состоянии. Всю ответственность, а также возможные последствия
                  за предоставление недостоверной или не актуальной персональной
                  информации несёт Пользователь.
                </div>
              </li>
            </ol>
          </li>
          <li class="privacy-policy-modal__list-out-element">
            <div class="privacy-policy-modal__list-out-element__title">
              Условия обработки персональной информации пользователя и её
              передачи третьим лицам:
            </div>
            <ol class="privacy-policy-modal__list-in">
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  ООО «Фабрика обуви» хранит и обрабатывает персональную
                  информацию пользователей в соответствии с действующими
                  нормативными актами, а также внутренними регламентами
                  созданными на их основе (имя, фамилия, отчество, адрес
                  электронной почты, пол, дату рождения, почтовый адрес,
                  домашний, рабочий, мобильный телефоны).
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  В отношении персональной информации пользователя сохраняется
                  ее конфиденциальность, кроме случаев добровольного
                  предоставления пользователем информации о себе для общего
                  доступа неограниченному кругу лиц.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  ООО «Фабрика обуви» защищает персональную информацию
                  Пользователя в соответствии с требованиями, предъявляемыми к
                  защите такого рода информации, и несет ответственность за
                  использование безопасных методов защиты такой информации.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Для защиты персональной информации Пользователя, обеспечения
                  ее надлежащего использования и предотвращения
                  несанкционированного и/или случайного доступа к ней, ООО
                  «Фабрика обуви» применяет необходимые и достаточные
                  технические и административные меры. Предоставляемая
                  Пользователем персональная информация хранится на серверах с
                  ограниченным доступом, расположенных в охраняемых помещениях.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  ООО «Фабрика обуви» вправе передать персональную информацию
                  пользователя (в том числе организациям, осуществляющим запись,
                  систематизацию, накопление, уточнение, хранение, извлечение,
                  непосредственно осуществляющим направление Пользователю
                  специальных предложений, информации о новых товарах и
                  рекламных акциях, обработку моих запросов и обращений, а так
                  же осуществляющим уничтожение персональной информации) третьим
                  лицам в следующих случаях:
                  <ol class="privacy-policy-modal__list-in">
                    <li class="privacy-policy-modal__list-in-element">
                      <div class="privacy-policy-modal__list-in-element__body">
                        Пользователь явно выразил свое согласие на такие
                        действия, т.е. отметил чекбокс «Принимаю Политику
                        конфиденциальности» при заполнении на Сайте форм с
                        персональной информацией;
                      </div>
                    </li>
                    <li class="privacy-policy-modal__list-in-element">
                      <div class="privacy-policy-modal__list-in-element__body">
                        Передача необходима в рамках использования пользователем
                        определенного Сервиса либо для оказания услуги
                        пользователю, выполнения обязательств по договору;
                      </div>
                    </li>
                    <li class="privacy-policy-modal__list-in-element">
                      <div class="privacy-policy-modal__list-in-element__body">
                        Передача предусмотрена российским или иным применимым
                        законодательством в рамках установленной
                        законодательством процедуры;
                      </div>
                    </li>
                    <li class="privacy-policy-modal__list-in-element">
                      <div class="privacy-policy-modal__list-in-element__body">
                        При обработке персональных данных пользователей ООО
                        «Фабрика обуви» руководствуется Федеральным законом РФ
                        «О персональных данных».
                      </div>
                    </li>
                  </ol>
                </div>
              </li>
            </ol>
          </li>
          <li class="privacy-policy-modal__list-out-element">
            <div class="privacy-policy-modal__list-out-element__title">
              Изменение Пользователем персональной информации:
            </div>
            <ol class="privacy-policy-modal__list-in">
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Пользователь может в любой момент изменить (обновить,
                  дополнить) предоставленную им персональную информацию или её
                  часть, а также параметры её конфиденциальности,
                  воспользовавшись функцией редактирования персональной
                  информации в персональном разделе личного пространства.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Пользователь вправе в любой момент потребовать удаления
                  предоставленной им персональной информации, обратившись в
                  Службу клиентской поддержки ООО «Фабрика обуви» по адресу
                  <a
                    class="privacy-policy-modal__link"
                    href="mailto:info@kapika.ru"
                    >info@kapika.ru</a
                  >
                  или по телефону
                  <a class="privacy-policy-modal__link" href="tel:88006008562"
                    >8(800)600-8562</a
                  >
                  .
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Для того, чтобы отказаться от получения новостных рассылок,
                  нажмите на соответствующую ссылку.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Оформление заказа – при выборе пункта «оформить заказ без
                  регистрации» в Интернет-магазине kapika.ru, для пользователя
                  автоматически создается учетная запись с сохранением внесенных
                  при оформлении данных. Данные хранятся в течении 24 часов и
                  автоматически удаляются в случае не активации со стороны
                  пользователя. Для активации регистрации на сайте, необходимо в
                  полученном после оформления заказа письме перейти по
                  предоставленной ссылке.
                </div>
              </li>
            </ol>
          </li>
          <li class="privacy-policy-modal__list-out-element">
            <div class="privacy-policy-modal__list-out-element__title">
              Подтверждение Политики:
            </div>
            <ol class="privacy-policy-modal__list-in">
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Каждая Сторона гарантирует другой Стороне, что обладает
                  необходимой право- и дееспособностью, а равно всеми правами и
                  полномочиями, необходимыми и достаточными для заключения и
                  исполнения Политики в соответствии с ее условиями.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Настоящая Политика вступает в силу с момента выражения
                  Пользователем согласия с его условиями в следующем порядке:
                  начиная использовать сервис/его отдельные функции Пользователь
                  считается принявшим условия Политики в полном объеме, без
                  всяких оговорок и исключений. В случае несогласия Пользователя
                  с каким-либо из условий Политики, Пользователь не вправе
                  использовать сервис.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Для того, чтобы отказаться от получения новостных рассылок,
                  нажмите на соответствующую ссылку.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  В случае если ООО «Фабрика обуви» были внесены какие-либо
                  изменения в Политику в порядке, предусмотренном настоящей
                  Политикой, с которыми Пользователь не согласен, он обязан
                  прекратить использование сервиса.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Отметка чекбокса о рекламной рассылке означает полное согласие
                  Пользователя на получение информации о специальных
                  предложениях, о новых товарах и рекламных акциях по сетям
                  электросвязи и по почтовой связи (включая, но не
                  ограничиваясь: SMS-рассылки, e-mail-рассылки) и обработку
                  своих персональных данных посредством сбора, записи,
                  систематизации, накопления, хранения, уточнения, извлечения,
                  использования, осуществляемую с использованием средств
                  автоматизации, в том числе в
                  информационно-телекоммуникационных сетях, или без
                  использования таких средств в целях организации направления
                  мне специальных предложений, информации о новых товарах и
                  рекламных акциях, обработки моих запросов и обращений.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  Указанное согласие Пользователя с условиями Политики, в том
                  числе порядком обработки персональной информации, действует 25
                  лет, если оно не было отозвано в соответствии со статьей 9
                  Федерального закона от 27.07.2006 № 152-ФЗ "О персональных
                  данных".
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  ООО «Фабрика» предпринимает коммерчески обоснованные усилия
                  для обеспечения функционирования Сайта согласно рабочему
                  графику, однако не гарантирует отсутствие перерывов, связанных
                  с техническими неисправностями, проведением профилактических
                  работ, а также не гарантирует полную или частичную
                  работоспособность Сайта/Сервиса.
                </div>
              </li>
              <li class="privacy-policy-modal__list-in-element">
                <div class="privacy-policy-modal__list-in-element__body">
                  В целях сбора статистических данных и идентификации
                  Пользователя ООО «Фабрика обуви» вправе устанавливать и
                  сохранять информацию об IP-адресах доступа Пользователей.
                </div>
              </li>
            </ol>
          </li>
          <li class="privacy-policy-modal__list-out-element">
            <div class="privacy-policy-modal__list-out-element__title">
              Изменение Политики конфиденциальности:
            </div>
            <span class="privacy-policy-modal__list-out__remark">
              Соглашение может быть изменено ООО «Фабрика обуви» без какого-либо
              специального уведомления, новая редакция Политики вступает в силу
              с момента ее размещения в сети Интернет, если иное не
              предусмотрено новой редакцией Политики. Если после того, как такие
              изменения в Политике вступят в силу, Пользователь продолжает
              работать с Сайтом, то Пользователь подтверждает свое согласие с
              изменениями. Если в какой-либо момент Пользователь больше не может
              соблюдать условия Политики, то Пользователь должен немедленно
              прекратить использование Сайта/сервисов.
            </span>
          </li>
          <li class="privacy-policy-modal__list-out-element">
            <div class="privacy-policy-modal__list-out-element__title">
              Обратная связь. Вопросы и предложения
            </div>
            <span class="privacy-policy-modal__list-out__remark">
              Все предложения или вопросы по поводу настоящего Соглашения
              следует сообщать в Службу клиентской поддержки ООО «Фабрика обуви»
              <a class="privacy-policy-modal__link" href="mailto:info@kapika.ru"
                >info@kapika.ru</a
              >, либо по адресу г. Москва, ул. Маршала Федоренко д.3, стр. 1.
              Работа службы поддержки с 9-18 часов по Московскому времени.
            </span>
          </li>
        </ol>
      </div>
      <div class="privacy-policy-modal__close" @click="$emit('cancel')">
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="31" height="31" rx="15.5" fill="white" />
          <path
            opacity="0.5"
            d="M16.4728 15.5001L20.7984 11.1743C21.0672 10.9058 21.0672 10.4701 20.7984 10.2016C20.5297 9.93281 20.0945 9.93281 19.8257 10.2016L15.5001 14.5274L11.1743 10.2016C10.9055 9.93281 10.4703 9.93281 10.2016 10.2016C9.93281 10.4701 9.93281 10.9058 10.2016 11.1743L14.5274 15.5001L10.2016 19.8259C9.93281 20.0944 9.93281 20.5301 10.2016 20.7987C10.3359 20.9328 10.512 21 10.6879 21C10.8638 21 11.0399 20.9328 11.1743 20.7984L15.5001 16.4726L19.8257 20.7984C19.9601 20.9328 20.1362 21 20.3121 21C20.488 21 20.6641 20.9328 20.7984 20.7984C21.0672 20.5299 21.0672 20.0942 20.7984 19.8257L16.4728 15.5001Z"
            fill="#41454B"
          />
        </svg>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";
export default {
  name: "PrivacyPolicyModal",
  components: {
    VueFinalModal,
  },
  inheritAttrs: false,
  emits: ["confirm", "cancel"],
  methods: {
    initModal() {},
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.modal-content) {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
}
.privacy-policy-modal {
  background: #fff;
  height: 100%;
  overflow-y: auto;
  position: relative;
  &__link {
    display: inline;
    color: #005eb8;
    line-height: 20px;
    &:hover {
      text-decoration: none;
    }
  }
  &__close {
    right: 30px;
    position: fixed;
    top: 39px;
    cursor: pointer;
    line-height: 0;
  }
  &__inner {
    padding: 50px 25px;
  }
  &__label {
    color: #41454b;
    font-family: Nunito, Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 1em;
  }
  &__list {
    &-out {
      padding-left: 50px;

      &-element {
        color: #41454b;
        font-family: Nunito, Arial, Helvetica, sans-serif;
        font-size: 20px;
        line-height: 26px;
        font-weight: 700;
        &__title {
          margin-top: 20px;
          margin-bottom: 30px;
        }
      }
      &__remark {
        color: #41454b;
        font-size: 16px;
        display: block;
        font-family: Helvetica, Arial, sans-serif;
        line-height: 24px;
        margin: 30px 0 24px 0;
        font-weight: 400;
      }
    }
    &-in {
      padding-left: 20px;
      font-family: Helvetica, Arial, sans-serif;
      list-style: none;
      counter-reset: li;
      &-element {
        margin-bottom: 16px;
        position: relative;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        &::before {
          counter-increment: li;
          content: counters(li, ".") ". ";
          position: absolute;
          left: -25px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &__body {
          font-family: Helvetica, Arial, sans-serif;
          color: #41454b;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          display: inline;
        }
      }
      &--dash {
        list-style: none;
      }
      &--dash > &-element {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: -30px;
          top: 10px;
          width: 19px;
          height: 2px;
          background-color: #41454b;
        }
      }
    }
  }
}
</style>