<template>
  <div class="" v-if="loading">
    <span class="loader"></span>
  </div>
  <div class="" v-else>
    <FormMain @onAuthorized="onAuthorized" v-if="!authorized" />
    <PageCard @exitProfile="exitProfile" v-else />
  </div>
</template>
<script>
import axios from "axios";
import FormMain from "./FormMain.vue";
import PageCard from "./PageCard.vue";

export default {
  data() {
    return {
      loading: true,
      authorized: false,
    };
  },
  methods: {
    onAuthorized() {
        this.authorized = true;
    },
    exitProfile(){
      this.authorized = false;
    }
  },
  mounted() {
    axios
      .get("/api/v1/profile", {})
      .catch((e) => {
        if (e.response.status == 401) {
          console.log(e.response);
          console.log("Пользователь не авторизован");
          this.authorized = false;
          this.loading = false
        } else if (e.response.status == 404) {
          console.log("Пользователь не зарегистрирован в программе лояльности");
          console.log(e.response);
          this.authorized = false;
          this.loading = false;
        } else {
          console.log(e.response);
          this.authorized = false;
          this.loading = false;
        }
      })
      .then((e) => {
        if (e?.status == 200) {
          this.authorized = true;
          this.loading = false;
        }
      });
  },
  components: { FormMain, PageCard },
};
</script>
<style lang="scss">
.loader {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #005EB8;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
</style>
