<template>
  <div class="form-main">
    <div class="form-main__inner">
      <div class="form-main-header">
        <div class="form-main-header__inner content-padding">
          <div class="form-main-header__logo">
            <img src="@/assets/images/logoForm.svg" alt="Логотип сайта" />
          </div>
          <div class="form-main-header__title">Наша бонусная программа</div>
        </div>
      </div>
      <form class="form-main-form" ref="mainForm">
        <div class="form-main-form__inner">
          <div class="form-main-form-group">
            <input
              type="tel"
              class="input-form"
              v-model="infoForm.phone"
              placeholder="Телефон"
              masked="true"
              v-mask="'+7 (###) ###-##-##'"
              :class="{
                'input-error': v$.infoForm.phone.$invalid && attemptSubmit,
              }"
            />
            <label class="input-error-message">{{ errorMessagePhone }}</label>
            <label class="form-main-error" v-if="phoneLogin">{{
              errorMessagePhone
            }}</label>
            <span class="form-main-form-group__sublabel"
              >На этот номер придет код подтверждения</span
            >
          </div>
          <div class="form-main-form-submit">
            <PrimaryButton @click="submitData()" :disabled="isLoading"
              >Войти</PrimaryButton
            >
          </div>
          <div class="form-main-form__registration">
            <div class="form-main-form__question">
              Вы все еще не участвуете в бонусной программе?
            </div>
            <router-link to="/registration">
              <SecondaryButton>Зарегистрироваться</SecondaryButton>
            </router-link>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ConfirmModalAuthorization
    v-model="showModalConfirm"
    :mainForm="infoForm"
    @opened="resetForm()"
    @closeForm="closeForm"
  />
</template>
<script>
import ConfirmModalAuthorization from "../components/modals/confirmModalAuthorization.vue";
import PrimaryButton from "../components/UI/PrimaryButton.vue";
import SecondaryButton from "../components/UI/SecondaryButton.vue";
import { useVuelidate } from "@vuelidate/core";
import { mask } from "vue-the-mask";
import { required, helpers } from "@vuelidate/validators";
import axios from "axios";
import { computed } from "vue";

const INIT_FORM = {
  phone: "",
};

export default {
  components: {
    PrimaryButton,
    SecondaryButton,
    ConfirmModalAuthorization,
},
  data() {
    return {
      showModalConfirm: false,
      isLoading: false,
      attemptSubmit: false,
      errorMessagePhone: "",
      phoneLogin: false,
      infoForm: Object.assign({}, INIT_FORM),
    };
  },
  directives: { mask },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      infoForm: {
        phone: {
          required,
          minLength(value) {
            if (value.replace(/[^\d]/g, "").length !== 11) {
              this.errorMessagePhone = "Неправильный номер телефона";
            }

            return value.replace(/[^\d]/g, "").length == 11;
          },
          isUnique: helpers.withAsync((value) => {
            let valueSubmit = value.replace(/[^\d]/g, "");
            if (valueSubmit.length !== 11) {
              this.phoneLogin = false;
              return false;
            } else {
              return true;
            }
          }),
        },
      },
    };
  },
  methods: {
    submitData() {
      this.attemptSubmit = true;
      if (!this.v$.infoForm.$invalid) {
        this.isLoading = true;
        axios
          .post("/api/v1/login/get-code", {
            phone: this.infoForm.phone,
          })
          .then(() => {
            this.showModalConfirm = true;
          })
          .finally(() => {
            this.isLoading = false;
          })
          .catch((e) => {
            if (e.response.data.errors) {
              this.errorMessagePhone = e.response.data.errors[0].message;
              this.phoneLogin = true;
            } else {
              this.errorMessagePhone = "";
              this.phoneLogin = false;
            }
          });
      }
    },
    closeForm(){
      this.showModalConfirm = false;
      this.$emit('onAuthorized')
    },
    resetForm() {
      this.attemptSubmit = false;
      this.$refs.mainForm.reset();
      this.infoForm = Object.assign({}, INIT_FORM);
      window.scrollTo(0, 0);
    },
    provide() {
      return {
        validationChildren: computed(
          () => this.v$.infoForm?.childrens.$each.$response.$data
        ),
        isSubbmited: computed(() => this.attemptSubmit),
      };
    },
  },
};
</script>
<style lang="scss">
.form-main {
  background-color: #f3f5f9;

  &__inner {
    max-width: 500px;
    margin: 0 auto;
    height: 100%;
    position: relative;
  }

  &-error {
    color: red;
    font-size: 12px;
    margin: 8px 0;
  }
  &-header {
    padding: 12px 0px 28px;
    &__logo {
      text-align: center;
      margin-bottom: 28px;
    }
    &__title {
      text-align: center;
      margin: 0 auto;
      max-width: 261px;
      font-weight: 700;
      font-family: "a_FuturaRound";
      font-size: 28px;
      color: #41454b;
      line-height: 32px;
      margin-bottom: 9px;
    }
    &__subtitle {
      color: #005eb8;
      font-size: 16px;
      font-family: "Helvetica";
      line-height: 22px;
      cursor: pointer;
    }
  }
  &-form {
    background: white;
    padding: 28px 16px 48px;
    border-radius: 24px 24px 0px 0px;
    height: 100%;
    &__question {
      font-size: 14px;
      line-height: 19px;
      opacity: 0.5;
      font-family: "Helvetica";
      margin-top: 11px;
      display: block;
      color: #41454b;
    }
    &-group {
      margin-bottom: 28px;
      position: relative;
      &:last-child {
        margin-bottom: 0px;
      }
      &__sublabel {
        font-size: 14px;
        line-height: 19px;
        opacity: 0.5;
        font-family: "Helvetica";
        margin-top: 11px;
        display: block;
        color: #41454b;
      }
      &__not-required {
        display: none;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        font-family: "Helvetica";
        color: rgba(65, 69, 75, 0.5);
        font-size: 14px;
        line-height: 19px;
      }
      & > input:invalid + &__not-required {
        display: inline-block;
      }
    }
    &-submit {
      margin-bottom: 42px;
      text-align: center;
      &__sublabel {
        font-size: 12px;
        line-height: 17px;
        color: #41454b;
        opacity: 0.5;
        display: block;
        margin-top: 20px;
        font-family: "Helvetica";
      }
    }
    &__registration {
      padding: 27px 31px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    &__question {
      text-align: center;
      width: 267px;
      margin-bottom: 22px;
    }
  }
}
</style>
